import { ApiService } from '../../api/api.service';
import { ELanguages } from 'desiren-core-lib/lib/enums/languages.enum';
import { UserModelData } from 'desiren-core-lib/lib/types/users';
import { ApplicationRef, inject, Injectable } from '@angular/core';
import { EAvailableLanguages, EKeysTransloco } from './translations.enum';
import { filter, fromEvent, map, of, Subject, switchMap, tap } from 'rxjs';
import { StorageDataKey, StorageService } from '../storage.service';
import { getBrowserLang, TranslocoService } from '@ngneat/transloco';
import { registerLocaleData } from '@angular/common';

import en from '@angular/common/locales/en';
import ru from '@angular/common/locales/ru';
import de from '@angular/common/locales/de';

@Injectable({
	providedIn: 'root',
})
export class TranslationsService {
	static availableLanguages = [EAvailableLanguages.ru, EAvailableLanguages.en, EAvailableLanguages.de];

	static defaultLang = EAvailableLanguages.en;
	public availableLanguagesTranslates: { [key in EAvailableLanguages]: string } = {
		[EAvailableLanguages.ru]: 'Русский',
		[EAvailableLanguages.en]: 'English',
		[EAvailableLanguages.de]: 'Deutsch',
	};
	public readonly keys: typeof EKeysTransloco = EKeysTransloco;
	public readonly currentLanguage = new Subject<EAvailableLanguages>();

	private readonly storageService: StorageService = inject(StorageService);
	private readonly apiService = inject(ApiService);
	private languageChangeSub = of(typeof window !== 'undefined')
		.pipe(
			filter(Boolean),
			switchMap((_) => fromEvent(window, 'storage')),
			map((data) => data as StorageEvent),
			filter((data) => data.key === StorageDataKey.language),
			filter((data) => Object.values(TranslationsService.availableLanguages).includes(data.newValue as EAvailableLanguages)),
			tap((data) => this.setActiveLang(data.newValue as EAvailableLanguages, true))
		)
		.subscribe();

	constructor(private translocoService: TranslocoService, private appRef: ApplicationRef) {}

	public translate(key: EKeysTransloco): string {
		return this.translocoService.translate(key);
	}

	public async load(): Promise<void> {
		const browserLang = getBrowserLang();
		const storageLanguage = this.storageService.getLocalData(StorageDataKey.language);
		const userStorageData = this.storageService.getLocalJsonData(StorageDataKey.apiUser) as UserModelData | null;
		if (userStorageData?.language && Object.values(TranslationsService.availableLanguages).includes(storageLanguage as unknown as EAvailableLanguages)) {
			return await this.setActiveLang(storageLanguage as unknown as EAvailableLanguages);
		}
		if (storageLanguage && Object.values(TranslationsService.availableLanguages).includes(storageLanguage as EAvailableLanguages)) {
			return await this.setActiveLang(storageLanguage as EAvailableLanguages);
		}
		if (Object.values(TranslationsService.availableLanguages).includes(browserLang as EAvailableLanguages)) {
			return await this.setActiveLang(browserLang as EAvailableLanguages);
		}

		return await this.setActiveLang(EAvailableLanguages.en);
	}

	public getActiveLang(): string {
		return this.translocoService.getActiveLang();
	}

	public async setActiveLang(language: EAvailableLanguages, withHttpRequest: boolean = false): Promise<void> {
		const userStorageData = this.storageService.getLocalJsonData(StorageDataKey.apiUser) as UserModelData | null;
		if (language === EAvailableLanguages.en) {
			registerLocaleData(en);
			const enLang = (await import('./locales/en')).default;
			this.translocoService.setTranslation(enLang, EAvailableLanguages.en);
		}
		if (language === EAvailableLanguages.ru) {
			registerLocaleData(ru);
			const ruLang = (await import('./locales/ru')).default;
			this.translocoService.setTranslation(ruLang, EAvailableLanguages.ru);
		}
		if (language === EAvailableLanguages.de) {
			registerLocaleData(de);
			const deLang = (await import('./locales/de')).default;
			this.translocoService.setTranslation(deLang, EAvailableLanguages.de);
		}
		this.translocoService.setActiveLang(language);
		this.storageService.setLocalData(StorageDataKey.language, language);
		this.currentLanguage.next(language);
		if (Object.values(ELanguages).includes(language as unknown as ELanguages) && withHttpRequest && userStorageData) {
			await this.apiService.changeUserLanguage(language as unknown as ELanguages);
		}
	}

	get availableLanguages(): String[] {
		return this.availableLanguages;
	}
}
